<template>
	<DialogBase
		:dialogWidth="900"
		:dialogModel="dialogModel"
		@closeDialog="val => closeDialog(val)"
		scrollable
	>
		<template v-slot:title>Apis Management</template>

		<v-row class="my-6">
			<v-col cols="4">
				<v-card
					class="fill-height transparent-1"
					@click="showCreateUpdateApiConfigDialog()"
				>
					<v-card-title>
						<v-spacer></v-spacer>
						Add New Api
						<v-spacer></v-spacer>
					</v-card-title>
					<v-card-text class="text-center">
						<v-icon color="primary" x-large>
							mdi-plus-circle-outline
						</v-icon>
					</v-card-text>
				</v-card>
			</v-col>
			<template v-for="(api, index) in apiConfigurations">
				<v-col cols="4" :key="index">
					<v-card
						class="fill-height transparent-1"
						@click="showCreateUpdateApiConfigDialog(api)"
					>
						<v-card-title>
							<v-spacer></v-spacer>
							{{ api.payload.name }}
							<v-spacer></v-spacer>
							<v-btn
								icon
								color="error"
								@click.stop="deleteApiConfiguration(api)"
								><v-icon>mdi-delete</v-icon></v-btn
							>
						</v-card-title>
						<v-card-text class="text-center">
							<p v-html="api.payload.notes"></p>
							<br />
							{{ api.payload.refs }}
						</v-card-text>
					</v-card>
				</v-col>
			</template>
		</v-row>
		<template v-slot:footer>
			<v-btn color="accent" outlined block @click="closeDialog()">Close</v-btn>
		</template>
		<template v-if="createUpdateApiConfigDialog">
			<createUpdateApiConfigDialog
				:dialogModel="createUpdateApiConfigDialog"
				:selectedModel="selectedApiConfig"
				@closeDialog="closeCreateUpdateApiConfigDialog()"
				@created="closeCreateUpdateApiConfigDialog(true)"
			/>
		</template>
	</DialogBase>
</template>

<script>
	import FormMaker from "@/components/FormMaker";
	import DialogBase from "@/components/dialogs/Base";
	import createUpdateApiConfigDialog from "@/components/dialogs/createUpdateApiConfigDialog";
	export default {
		components: { DialogBase, FormMaker, createUpdateApiConfigDialog },
		props: {
			dialogModel: { required: true, type: Boolean },
			selectedModel: { required: true, default: null }
		},
		data() {
			return {
				createUpdateApiConfigDialog: false,
				selectedApiConfig: null,
				apiConfigurations: []
			};
		},
		mounted() {
			this.getApiConfigurations();
		},
		computed: {},
		methods: {
			closeDialog(val = false) {
				this.$emit("closeDialog", val);
			},
			showCreateUpdateApiConfigDialog(selectedApiConfig = null) {
				this.closeCreateUpdateApiConfigDialog();
				if (selectedApiConfig)
					this.selectedApiConfig = Object.assign({}, selectedApiConfig);
				this.createUpdateApiConfigDialog = true;
			},
			closeCreateUpdateApiConfigDialog(loadApis = false) {
				this.selectedApiConfig = null;
				this.createUpdateApiConfigDialog = false;
				if (loadApis) this.getApiConfigurations();
			},
			async deleteApiConfiguration(api) {
				if (
					!confirm("By deleting this api for this carrier will stop working...")
				)
					return false;
				if (!confirm("Are you sure you wanted to delete this api config?"))
					return false;
				let data = {
					api_config_id: api?.id,
					user_id: this.currentUser?.id
				};
				return await this.post(
					this.baseUrl + `/delete-api-configuration`,
					data,
					true
				).then(data => {
					if (data == "undefined") return;
					if (data.code == 200) {
						this.getApiConfigurations();
					}
				});
			},
			async getApiConfigurations() {
				let data = {
					user_id: this.currentUser?.id
				};
				return await this.post(
					this.baseUrl + `/get-api-configurations`,
					data,
					false
				).then(data => {
					if (data == "undefined") return;
					if (data.code == 200) {
						this.apiConfigurations = data.data.configs;
						// console.log(JSON.stringify(this.apiConfigurations));
					}
				});
			}
		}
	};
</script>
